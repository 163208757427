import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Grid,
  TablePagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";

const CreateTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTestimonialId, setSelectedTestimonialId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [photo, setPhoto] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    review: "",
    designation: "",
    rating: "",
  });

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://api.thelearnskills.com/api/v1/testimonials"
        );
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTestimonials();
  }, []);

  const handleClickOpen = () => {
    setEditMode(false);
    setFormData({
      name: "",
      review: "",
      designation: "",
      rating: "",
    });
    setPhoto(null);
    setOpen(true);
  };

  const handleImageChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEdit = (testimonial) => {
    setEditMode(true);
    setSelectedTestimonialId(testimonial._id);
    setFormData({
      name: testimonial.name,
      review: testimonial.review,
      designation: testimonial.designation,
      rating: testimonial.rating,
    });
    setPhoto(null);
    setOpen(true);
  };

  const handleDelete = async (testimonialId) => {
    try {
      await axios.delete(
        `https://api.thelearnskills.com/api/v1/testimonials/${testimonialId}`
      );
      setTestimonials((prevTestimonials) =>
        prevTestimonials.filter(
          (testimonial) => testimonial._id !== testimonialId
        )
      );
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      if (photo) {
        formDataToSend.append("photo", photo);
      }

      if (editMode) {
        await axios.put(
          `https://api.thelearnskills.com/api/v1/testimonials/edit/${selectedTestimonialId}`,
          formDataToSend,
          config
        );
      } else {
        await axios.post(
          "https://api.thelearnskills.com/api/v1/testimonials/create",
          formDataToSend,
          config
        );
      }

      setOpen(false);
      const response = await axios.get(
        "https://api.thelearnskills.com/api/v1/testimonials"
      );
      setTestimonials(response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <div style={{ marginTop: "4rem" }}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create Testimonial
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Testimonial</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label="Review"
            type="text"
            fullWidth
            name="review"
            value={formData.review}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label="Designation"
            type="text"
            fullWidth
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label="Rating"
            type="number"
            fullWidth
            name="rating"
            value={formData.rating}
            onChange={handleChange}
            required
            inputProps={{ min: 1, max: 5 }}
          />
          <Button variant="contained" component="label">
            Upload Photo
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {photo && (
            <div>
              <img
                src={URL.createObjectURL(photo)}
                alt="Testimonial"
                width="100"
                height="100"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper} sx={{ mt: 3, boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F5F7F8" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Sr No.
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Review
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Designation
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Rating
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Photo
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  border: "none",
                  textTransform: "uppercase",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonials.map((testimonial, index) => (
              <TableRow key={testimonial._id}>
                <TableCell sx={{ fontWeight: "bold" }}>{index + 1}</TableCell>
                <TableCell sx={{ textTransform: "uppercase", color: "blue" }}>
                  {testimonial.name}
                </TableCell>
                <TableCell sx={{ textTransform: "uppercase" }}>
                  {testimonial.review}
                </TableCell>
                <TableCell sx={{ textTransform: "uppercase" }}>
                  {testimonial.designation}
                </TableCell>
                <TableCell sx={{ textTransform: "uppercase" }}>
                  {testimonial.rating}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {testimonial.photo && (
                    <img
                      src={`https://api.thelearnskills.com/${testimonial.photo}`}
                      alt={`Testimonial ${testimonial.name}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell sx={{ textTransform: "uppercase" }}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEdit(testimonial)}
                    sx={{ color: "blue" }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(testimonial._id)}
                    sx={{ color: "red" }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={testimonials.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default CreateTestimonials;
