import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from 'axios';

const JobForm = ({ open, handleClose, fetchJobPosters, editData }) => {
  const [formData, setFormData] = useState({
    title: "",
    companyName: "",
    salaryRange: "",
    location: "",
    aboutJob: "",
    qualification: [],
    experience: "",
    category: "",
    applicationLastDate: "",
    skills: [],
    whoCanApply: [],
    numberOfOpenings: "",
    aboutCompany: "",
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        title: editData.title,
        companyName: editData.companyName,
        salaryRange: editData.salaryRange,
        location: editData.location,
        aboutJob: editData.aboutJob,
        qualification: editData.qualification,
        experience: editData.experience,
        category: editData.category,
        applicationLastDate: editData.applicationLastDate,
        skills: editData.skills,
        whoCanApply: editData.whoCanApply,
        numberOfOpenings: editData.numberOfOpenings,
        aboutCompany: editData.aboutCompany,
      });
    } else {
      setFormData({
        title: "",
        companyName: "",
        salaryRange: "",
        location: "",
        aboutJob: "",
        qualification: [],
        experience: "",
        category: "",
        applicationLastDate: "",
        skills: [],
        whoCanApply: [],
        numberOfOpenings: "",
        aboutCompany: "",
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleArrayChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.split(",") });
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      if (editData) {
        // Update existing job posting
        await axios.put(
          `https://api.thelearnskills.com/api/v1/job-postings/update/${editData._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        // Create new job posting
        await axios.post(
          "https://api.thelearnskills.com/api/v1/job-postings/create",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      fetchJobPosters(); // Refresh the table
      handleClose(); // Close the form
    } catch (error) {
      console.error("Error submitting job posting:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{editData ? "Edit Job" : "Create New Job"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Company Name"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Salary Range"
          name="salaryRange"
          value={formData.salaryRange}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Location"
          name="location"
          value={formData.location}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="About Job"
          name="aboutJob"
          value={formData.aboutJob}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Qualifications (comma separated)"
          name="qualification"
          value={formData.qualification.join(",")}
          onChange={handleArrayChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Experience"
          name="experience"
          value={formData.experience}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Category</InputLabel>
          <Select
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <MenuItem value="Internship">Internship</MenuItem>
            <MenuItem value="Full-Time">Full-Time</MenuItem>
            <MenuItem value="Part-Time">Part-Time</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Application Last Date"
          name="applicationLastDate"
          type="date"
          value={formData.applicationLastDate}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Skills (comma separated)"
          name="skills"
          value={formData.skills.join(",")}
          onChange={handleArrayChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Who Can Apply (comma separated)"
          name="whoCanApply"
          value={formData.whoCanApply.join(",")}
          onChange={handleArrayChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Number of Openings"
          name="numberOfOpenings"
          value={formData.numberOfOpenings}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="number"
        />
        <TextField
          label="About Company"
          name="aboutCompany"
          value={formData.aboutCompany}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {editData ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobForm;