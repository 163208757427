import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";

const LeadsTable = () => {
  const [leads, setLeads] = useState([]); // Initialize leads as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(
          "https://api.thelearnskills.com/api/v1/leads"
        ); // Replace with your API endpoint
        console.log(response.data); // Log the response to verify the structure

        // Ensure the response data is in the expected format
        if (response.data && response.data.leads) {
          setLeads(response.data.leads);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        setError(error.message || "Failed to fetch leads");
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);

  // Function to get the course name based on the course type
  const getCourseName = (lead) => {
    if (lead.courseType === "vocational-education") {
      return lead.course?.programName || "N/A";
    } else if (lead.courseType === "workshop") {
      return lead.course?.workshopName || "N/A";
    } else if (lead.courseType === "short-term-courses") {
      return lead.course?.courseName || "N/A";
    }
    return "N/A";
  };

  return (
    <Box p={3} marginTop="4rem">
      <Typography variant="h4" fontWeight="bold">
        Leads
      </Typography>
      {loading ? (
        <Typography variant="body1" mt={2}>
          Loading...
        </Typography>
      ) : error ? (
        <Typography variant="body1" color="error" mt={2}>
          {error}
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#F5F7F8" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  Course ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  Course Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "8px",
                    textTransform: "uppercase",
                  }}
                >
                  Course Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads && leads.length > 0 ? (
                leads.map((lead, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        textTransform: "uppercase",
                        padding: "10px",
                        color: "blue",
                      }}
                    >
                      {lead.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: "uppercase",
                        padding: "8px",
                      }}
                    >
                      {lead.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: "uppercase",
                        padding: "8px",
                      }}
                    >
                      {lead.phone}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: "uppercase",
                        padding: "8px",
                      }}
                    >
                      {lead.course?._id}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: "uppercase",
                        padding: "8px",
                      }}
                    >
                      {getCourseName(lead)}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: "uppercase",
                        padding: "8px",
                      }}
                    >
                      {lead.courseType}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No leads available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default LeadsTable;
