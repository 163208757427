import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import JobForm from "./JobForm"; // Adjust the import path as needed

const AllJobs = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null); // State to hold job data for editing

  useEffect(() => {
    fetchJobPosters();
  }, []);

  const fetchJobPosters = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.thelearnskills.com/api/v1/job-postings/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data.jobPostings);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `https://api.thelearnskills.com/api/v1/job-postings/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.error("Error deleting job poster:", error);
    }
  };

  const handleOpen = (job = null) => {
    setEditData(job); // Set the job data for editing if provided
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(null); // Reset edit data when closing
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()} // Open form for new job creation
        sx={{ mt: 8 }}
      >
        Create Job
      </Button>

      <TableContainer component={Paper} sx={{ mt: 2, boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F5F7F8" }}>
              {[
                "Sr. No",
                "TITLE",
                "COMPANY NAME",
                "SALARY RANGE",
                "LOCATION",
                "APPLICATION LAST DATE",
                "NUMBER OF OPENINGS",
                "ACTIONS",
              ].map((header) => (
                <TableCell
                  key={header}
                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell style={{ fontWeight: "bold" }}>{index + 1}</TableCell>
                <TableCell sx={{ color: "blue" }}>{row.title}</TableCell>
                <TableCell>{row.companyName}</TableCell>
                <TableCell>{row.salaryRange}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>
                  {new Date(row.applicationLastDate).toLocaleDateString()}
                </TableCell>
                <TableCell>{row.numberOfOpenings}</TableCell>
                <TableCell>
                  {/* Edit Button */}
                  <IconButton
                    aria-label="edit"
                    style={{ color: "blue" }}
                    onClick={() => handleOpen(row)}
                  >
                    <Edit />
                  </IconButton>

                  {/* Delete Button */}
                  <IconButton
                    aria-label="delete"
                    style={{ color: "red" }}
                    onClick={() => handleDelete(row._id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pass editData to JobForm to pre-fill data when editing */}
      <JobForm
        open={open}
        handleClose={handleClose}
        fetchJobPosters={fetchJobPosters}
        editData={editData} // Send job data to JobForm
      />
    </div>
  );
};

export default AllJobs;