import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import axios from "axios";

const AllJobs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchJobPosters();
  }, []);

  const fetchJobPosters = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.thelearnskills.com/api/v1/job-applications/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API Response:", response.data); // Log full response
      setData(response.data.applications || []); // Corrected to access applications
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Ensure data is an empty array on error
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `https://api.thelearnskills.com/api/v1/job-applications/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.error("Error deleting job poster:", error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 10, boxShadow: 3 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F5F7F8" }}>
            {[
              "Sr. No",
              "JOB ID",
              "USER ID",
              "VIDEO URL",
              "RESUME URL",
              "ACTIONS",
            ].map((header) => (
              <TableCell
                key={header}
                style={{ fontWeight: "bold", fontSize: "1rem" }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell style={{ fontWeight: "bold" }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ color: "blue" }}>
                  {row?.jobId?.title}
                </TableCell>
                <TableCell>{row.userId || "N/A"}</TableCell>
                <TableCell>
                  <video width="200" height="100" controls>
                    <source
                      src={`https://api.thelearnskills.com/${row.videoUrl}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </TableCell>
                <TableCell>
                  <a
                    href={`https://api.thelearnskills.com/${row.resumeUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Resume
                  </a>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    style={{ color: "red" }}
                    onClick={() => handleDelete(row._id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography variant="body1">No jobs found.</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllJobs;
