import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { alpha, styled } from "@mui/material/styles";

const localizer = momentLocalizer(moment);

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  border: `1px solid ${theme.palette.divider}`, // Add border
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await axios.get(
        "https://api.thelearnskills.com/api/v1/meeting/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("API Response:", response.data); // Log the response
      const meetings = response.data.meetings.map((meeting) => ({
        id: meeting._id,
        start: moment(meeting.date).toDate(),
        end: moment(meeting.date).toDate(),
        title: meeting.title,
        description: meeting.description,
        meetingLink: meeting.meetingLink,
        assignedTo: meeting.assignedTo,
        createdBy: meeting.createdBy.name,
      }));

      setEvents(meetings);
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handleCreateMeeting = async (meeting) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      await axios.post(
        "https://api.thelearnskills.com/api/v1/meeting/create",
        meeting,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      fetchMeetings(); // Refresh the calendar with new meeting
      setCreateModalOpen(false);
    } catch (error) {
      console.error("Error creating meeting:", error);
    }
  };

  const handleUpdateMeeting = async (updatedEvent) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      await axios.put(
        `https://api.thelearnskills.com/api/v1/meeting/${updatedEvent.id}`,
        updatedEvent,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      fetchMeetings(); // Refresh the calendar after update
    } catch (error) {
      console.error("Error updating meeting:", error);
    }
  };

  const handleDeleteMeeting = async (eventId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmed) {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token found");

        await axios.delete(
          `https://api.thelearnskills.com/api/v1/meeting/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        fetchMeetings(); // Refresh the calendar after deletion
        setModalOpen(false);
      } catch (error) {
        console.error("Error deleting meeting:", error);
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null); // Reset selected event
  };

  return (
    <div style={{ marginTop: "4rem" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCreateModalOpen(true)}
        style={{ marginBottom: "1rem" }}
      >
        Create Meeting
      </Button>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={handleEventClick}
      />
      {selectedEvent && (
        <TaskModal
          open={modalOpen}
          onClose={handleCloseModal}
          event={selectedEvent}
          onSave={(updatedEvent) => {
            handleUpdateMeeting(updatedEvent);
            handleCloseModal(); // Close modal after saving
          }}
          onDelete={() => handleDeleteMeeting(selectedEvent.id)}
        />
      )}
      <CreateMeetingModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={handleCreateMeeting}
      />
    </div>
  );
};

const TaskModal = ({ open, onClose, event, onSave, onDelete }) => {
  const [title, setTitle] = useState(event.title);
  const [description, setDescription] = useState(event.description);
  const [meetingLink, setMeetingLink] = useState(event.meetingLink);
  const [assignedTo, setAssignedTo] = useState(
    event.assignedTo.map((user) => user._id)
  ); // Store IDs
  const [date, setDate] = useState(moment(event.start));
  const [users, setUsers] = useState([]); // Initialize as an empty array
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state

  useEffect(() => {
    setTitle(event.title);
    setDescription(event.description);
    setMeetingLink(event.meetingLink);
    setDate(moment(event.start));
    setAssignedTo(event.assignedTo.map((user) => user._id)); // Update assignedTo state

    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get("https://api.thelearnskills.com/api/v1/auth/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.success && Array.isArray(response.data.users)) {
            setUsers(response.data.users); // Set the users array
          } else {
            console.error("Invalid API response structure:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    } else {
      console.error("No token found");
    }
  }, [event]);

  const handleAssignedToChange = (event) => {
    setAssignedTo(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSave = () => {
    const updatedEvent = {
      id: event.id,
      title,
      description,
      meetingLink,
      assignedTo,
      date: date.toISOString(),
    };
    onSave(updatedEvent);
  };

  // Helper function to get selected user names
  const getSelectedUserNames = () => {
    return users
      .filter((user) => assignedTo.includes(user._id)) // Filter selected users
      .map((user) => user.name) // Get their names
      .join(", "); // Join names into a string
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Meeting</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Meeting Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Meeting Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Meeting Link"
          type="url"
          fullWidth
          value={meetingLink}
          onChange={(e) => setMeetingLink(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Meeting Date"
            value={date}
            onChange={(newDate) => setDate(newDate)}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="dense" />
            )}
          />
        </LocalizationProvider>

        <FormControl fullWidth margin="dense" style={{ marginTop: "1rem" }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search users"
              value={searchTerm}
              onChange={handleSearchChange}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <InputLabel id="assigned-to-label">Assigned To</InputLabel>
          <Select
            labelId="assigned-to-label"
            multiple
            value={assignedTo} // Store IDs internally
            onChange={handleAssignedToChange}
            renderValue={() => getSelectedUserNames()} // Display names instead of IDs
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {filteredUsers.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                <Checkbox checked={assignedTo.indexOf(user._id) > -1} />
                <ListItemText primary={user.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} color="secondary">
          Delete
        </Button>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateMeetingModal = ({ open, onClose, onCreate }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [date, setDate] = useState(moment());
  const [assignedTo, setAssignedTo] = useState([]); // Store IDs
  const [users, setUsers] = useState([]); // Initialize as an empty array
  const [time, setTime] = useState(moment().format("HH:mm"));
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get("https://api.thelearnskills.com/api/v1/auth/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.success && Array.isArray(response.data.users)) {
            setUsers(response.data.users); // Set the users array
          } else {
            console.error("Invalid API response structure:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    } else {
      console.error("No token found");
    }
  }, []);

  const handleAssignedToChange = (event) => {
    // event.target.value contains the selected IDs
    setAssignedTo(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreate = () => {
    const [hours, minutes] = time.split(":").map(Number);
    const meetingDate = date
      .clone()
      .set({ hour: hours, minute: minutes })
      .toISOString();

    const meeting = {
      title,
      description,
      meetingLink,
      date: meetingDate,
      time: time, // Include the `time` field
      assignedTo, // Send IDs to the API
    };

    console.log("Meeting object being sent:", meeting); // Debugging

    onCreate(meeting);
  };

  // Helper function to get selected user names
  const getSelectedUserNames = () => {
    return users
      .filter((user) => assignedTo.includes(user._id)) // Filter selected users
      .map((user) => user.name) // Get their names
      .join(", "); // Join names into a string
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Meeting</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Meeting Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Meeting Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Meeting Link"
          type="url"
          fullWidth
          value={meetingLink}
          onChange={(e) => setMeetingLink(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Meeting Date"
            value={date}
            onChange={(newDate) => setDate(newDate)}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="dense" />
            )}
          />
        </LocalizationProvider>
        <TextField
          margin="dense"
          label="Meeting Time"
          type="time"
          fullWidth
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search assigned to..."
            value={searchTerm}
            onChange={handleSearchChange}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <FormControl fullWidth margin="dense" style={{ marginTop: "1rem" }}>
          <InputLabel id="assigned-to-label">Assigned To</InputLabel>
          <Select
            labelId="assigned-to-label"
            multiple
            value={assignedTo} // Store IDs internally
            onChange={handleAssignedToChange}
            renderValue={() => getSelectedUserNames()} // Display names instead of IDs
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {filteredUsers.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                <Checkbox checked={assignedTo.indexOf(user._id) > -1} />
                <ListItemText primary={user.name} secondary={user._id} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyCalendar;
